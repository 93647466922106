import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = []
const requireComponent = require.context('../views', false, /\.vue$/)

requireComponent.keys().forEach((element: string, key: any) => {
  const vueName = element.replace('./', '')
  const name = vueName.split('.')[0].toLowerCase()
  routes.push({
    path: '/' + name,
    name: name,
    component: () => import('../views/' + vueName)
  })
})

const router = createRouter({
  history: createWebHashHistory('/web/'),
  routes: [
    {
      path: '/',
      component: () => import('../../src/views/dataCard.vue'),
      meta: {
        title: '产品详情'
      }
    },
  ]
})

router.beforeEach((to: any) => {
  // console.log(to)
  if (to.meta.title) {
    document.title = to.meta.title
  }
})
export default router
