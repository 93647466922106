<template>
  <div class="box">
    <img src="images/测试1.jpeg">
    <img src="images/测试2.jpeg">
  </div>
</template>

<script setup>
</script>
<style lang="scss">
.box {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 100%;
  }
}
</style>
